import { connect } from "react-redux";
import SimpleModal from "./SimpleModal";
import { sendForm } from "../../store/actions";
import { modalitiesRTB } from "constants/modalities";

const mapStateToProps = (state, ownProps) => {
  const { formReducer, modalReducer } = state;
  const { submissionAttempted, validForm } = formReducer;
  let type = validForm
    ? formReducer.pricing.total.premium === 0
      ? "RTB"
      : formReducer.values.paymentOption.selection
    : "Error";

  const { pricing } = formReducer;

  if (modalReducer.type  === "Generic") {
    type = "Generic";
  }
  if(modalReducer.type === "GenericYesOrNo"){
    type = "GenericYesOrNo";
  }
  let finalPremium = Object.values(pricing).reduce(
    (acc, curr) => Math.max(acc, curr.premium || 0),
    0
  );
  let finalOptions = Object.values(pricing).reduce(
    (acc, curr) => acc + curr.optional || acc,
    0
  );

  let totalstripe = (finalPremium + finalOptions) * pricing.tax;
  let email = formReducer.values.applicantInformation.email;
  //when the customer is a new customer
  // and the customer is from QC and is asking for a renewal
  if ((formReducer.values.applicantInformation.newPolicyyes_no === "yes" && validForm) ||
  (formReducer.values["applicantInformation"].province === "Quebec" && formReducer.values["applicantInformation"].exisitngPolicyyes_no === "yes") && validForm) {
    type = "RTB";
  }
  return {
    modal: modalReducer.showModal,
    type,
    validForm,
    message: modalReducer.message,
    totalstripe,
    email
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    closeModal: () => {
      dispatch({ type: "CLOSE_MODAL" });
    },
    submitApplication: token => {
      dispatch(sendForm(token));
    },
    modalSubmission: () => {
      dispatch({ type: "BEGIN_SUBMISSION" });
    },
    setAttestation:()=>{
      dispatch({type: "UPDATE_FORM", path: "RMT.skAttestation", value: "true"});
      dispatch({type:"HIDE_MODAL"});
    },
    resetForm: message => {
      dispatch({ type: "HANDLE_RESET_FORM", message });
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SimpleModal);
