import React, { useEffect } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
// import TableBody from "@material-ui/core/TableBody";
// import TableCell from "@material-ui/core/TableCell";
// import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Button } from "@material-ui/core";

import { handlePrettyForm } from "utils";

function helper(pf, i) {
  if (typeof pf !== "object") {
    return <>{pf}</>;
  }
  // if (i > 0) {
  //     return <>{Object.entries(pf).map((entry) => entry[1])}</>;;
  // }

  return (
    <>
      {Object.entries(pf).map(entry => {
        return (
          <TableRow style={i === 0 ? { width: "50%" } : {}}>
            {<td>{entry[0]}</td>} <td>{helper(entry[1], i + 1)}</td>
          </TableRow>
        );
      })}
    </>
  );
}

function getTable(pf) {
  return (
    <>
      {Object.entries(pf).map(entry => {
        return (
          <>
            <Typography variant="h6">{entry[0]}</Typography>{" "}
            <Table style={{ width: "50%" }}>{helper(entry[1], 0)}</Table>
          </>
        );
      })}
    </>
  );
}

function solve(object, i) {
  if (typeof object === "object") {
    let txt = [];
    Object.keys(object).forEach(function (value, index) {
      if (i === 0) {
        txt.push(
          <>
            <br />
            <Typography variant="h6"> {value} </Typography>
            <br />

            <Table style={{ width: "100%" }}>
              {solve(object[value], i + 1)}
            </Table>
          </>
        );
      } else {
        if (typeof object[value] === "object") {
          txt.push(
            <>
              <TableRow>
                <td>{value}</td>
                <td>
                  <Table style={{ width: "100%", border: 1, borderRadius: 5 }}>
                    {solve(object[value], i + 1)}
                  </Table>
                </td>
              </TableRow>
            </>
          );
        } else {
          txt.push(
            <>
              <TableRow>
                <td style={{ width: "50%" }}>{value}</td>{" "}
                <td>{object[value]}</td>
              </TableRow>
            </>
          );
        }
      }
    });
    return txt;
  } else if (typeof object != "object" && Array.isArray(object)) {
    return object.reduce(function (acc, index) {
      return (acc += acc[index]);
    }, "");
  } else {
    return object;
  }
}

const Success = props => {
  const { location, history, form, successFullSubmission, values, visible } = props;
  useEffect(() => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    if ('scrollRestoration' in window.history) {
      // Back off, browser, I got this...
      window.history.scrollRestoration = 'manual';
    }

  })

  if (!successFullSubmission) {
    history.push("/");
    return null;
  }

  // if (!successFullSubmission) {
  //   history.push("/");
  //   return null;
  // }
  return (
    <div className="formContainer">
      <>
        <Card>
          <CardContent>
            <div style={{ display: "flex" }}>
              <Typography variant={"h5"} style={{ flex: 1 }}>
                Your application has been processed and is under review.{" "}
              </Typography>
              <Button onClick={() => window.print()}>Print</Button>
            </div>

            <hr />
            <Typography>
              If you see any errors in the submission, contact the office at{" "}
              <a href="mailto:info@ineedapolicy.com">info@ineedapolicy.com</a>
            </Typography>
            <br></br>
            <Typography variant="subtitle2">
                Thank you for your application, please note once we have processed your renewal/application we will email your certificate of insurance to you.  Please note this can take 3-4 business days.  Make sure to check your junk mail as the email will come from one of our team members @brokerlink.ca.
            </Typography>
            {/* {getTable(location.state.prettyForm)} */}
            {solve(
              handlePrettyForm(
                values,
                visible
              ),
              0
            )}
          </CardContent>
        </Card>
      </>
    </div>
  );
};
export default Success;
