const initialState = {
  showModal: false,
  message: ""
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "SUBMISSION":
      return {
        showModal: true,
        message: ""
      };
    case "GENERIC_MODAL":
      return {
        showModal: true,
        type: "Generic",
        message: action.message
      };
    case "GENERIC_YN_MODAL":
      return {
        showModal: true,
        type: "GenericYesOrNo",
        message: action.message
      };
    case "CLOSE_MODAL":
      return initialState;
    case "FORM_SUBMISSION_SUCCESS":
      return initialState;
    case "FORM_SUBMISSION_ERROR":
      return {
        showModal: true,
        message: "Something went wrong with your submission, please try again or contact our office."
      };
      case "HIDE_MODAL":
        return{
          showModal: false,
          type: "GenericYesOrNo"
        }
    default:
      return state;
  }
};
