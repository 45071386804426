import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import styles from "./Payment.module.css";

const Payment = (props) => {
  const {
    totalstripe,
    finalPremium,
    finalOptions,
    handleChange,
    handleMount,
    handleUnmount,
    error,
  } = props;
  const [value, setValue] = useState(props.value);
  const isError = error && error.value === true;

  useEffect(() => {
    handleMount();
    return () => {
      handleUnmount();
    };
  }, []);

  return (
    <>
      <Grid
        container
        spacing={0}
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <br />

        <Grid item xs={12} sm={6}>
          <div>
            <Typography variant={"subtitle1"} align={"left"}>
              {" "}
              <strong>Select your payment option:</strong>
            </Typography>
          </div>
        </Grid>
      </Grid>
      <br />
      <Grid
        container
        spacing={0}
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid item>
          <table style={{ borderWidth: 0 }}>
            <tbody>
              <tr>
                <td style={{ borderWidth: 0 }}>
                  <Typography variant="h6">YOUR SUBTOTAL:</Typography>{" "}
                </td>
                <td style={{ borderWidth: 0 }} align="right">
                  <Typography variant="h6">
                    <span style={{ color: "#333333" }}>
                      ${parseFloat(finalPremium + finalOptions).toFixed(2)}
                    </span>
                  </Typography>
                </td>
              </tr>

              <tr>
                <td style={{ borderWidth: 0 }}>
                  <Typography variant="h6">TAX:</Typography>{" "}
                </td>
                <td style={{ borderWidth: 0 }} align="right">
                  <Typography variant="h6">
                    <span style={{ color: "#333333" }}>
                      $
                      {parseFloat(
                        totalstripe - (finalPremium + finalOptions)
                      ).toFixed(2)}
                    </span>
                  </Typography>
                </td>
              </tr>

              <tr>
                <td style={{ borderWidth: 0 }}>
                  {" "}
                  <Typography variant="h6">GRAND TOTAL:</Typography>{" "}
                </td>
                <td style={{ borderWidth: 0 }} align="right">
                  <Typography variant="h6">
                    <span style={{ color: "#333333" }}>
                      ${parseFloat(totalstripe).toFixed(2)}
                    </span>
                  </Typography>
                </td>
              </tr>
            </tbody>
          </table>

          <div>
            <Typography variant={"subtitle1"} align={"left"}>
              {" "}
              Please note the premium is fully retained.
            </Typography>
          </div>

          <RadioGroup
            id="paymentOption.selection"
            aria-label="yesno"
            name="paymentOption.selection"
            style={{
              flexDirection: "column",
              justifyContent: "space-around",
              backgroundColor: !isError ? "white" : "rgb(244, 67, 54, 0.1)",
              borderRadius: 10,
              paddingBottom: 15,
              paddingLeft: 15,
              paddingRight: 15,
              paddingTop: 15,
              marginTop: 15,
            }}
            onChange={(e) => {
              setValue(e.target.value);
              handleChange("paymentOption.selection", e.target.value);
            }}
            value={value}
          >
            <FormControlLabel
              value="Credit/Debit"
              control={<Radio color="default" />}
              label="Credit/Debit"
            />
            <FormControlLabel
              value="E-Transfer"
              control={<Radio color="default" />}
              label="E-Transfer"
            />

            <FormControlLabel
              value="Cheque"
              control={<Radio color="default" />}
              label="Cheque"
            />
          </RadioGroup>

          {isError ? (
            <div style={{ flex: "1 0 50%" }}>
              <span className={styles.helpText}>{props.error.message}</span>
            </div>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
    </>
  );
};
export default Payment;
