import React, { useState } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import StripeCheckout from "react-stripe-checkout";
import CircularProgress from "@material-ui/core/CircularProgress";

function getModalStyle() {
  return {
    top: `${50}%`,
    left: `${50}%`,
    transform: `translate(-${50}%, -${50}%)`
  };
}

const styles = theme => ({
  paper: {
    position: "absolute",
    width: "500px",
    maxWidth: "80%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: "none"
  }
});

const EtransferModalContent = props => {
  const [clicked, setClicked] = useState(false);
  return (
    <>
      <Typography variant="h6" id="modal-title">
        E-transfer Payment instructions
      </Typography>
      <Typography variant="subtitle1" id="simple-modal-description">
        Please follow the steps outlined below to submit payment
      </Typography>
      <br />

      <Typography variant="subtitle1" id="simple-modal-description">
        1. Log into your online banking
      </Typography>
      <Typography variant="subtitle1" id="simple-modal-description">
        2. Choose interact e-transfer
      </Typography>
      <Typography variant="subtitle1" id="simple-modal-description">
        3. Enter recipient's email and amount (Please use BrokerLink as the recipient and use{" "}
        <a href="mailto:accounts@ineedapolicy.com">accounts@ineedapolicy.com</a>{" "}
        as our email)
      </Typography>
      <Typography variant="subtitle1" id="simple-modal-description">
        4. Under MEMO if you are sending as a company or for someone else you
        MUST include the insured's full name here
      </Typography>
      <Typography variant="subtitle1" id="simple-modal-description">
        5. Send
      </Typography>
      <Button
        onClick={() => {
          props.submitApplication();
          setClicked(true)

        }}
        // onClick={() => {
        //   onClick();
        //   this.setState({ count: this.state.count + 1 });
        // }}
        disabled={clicked}
        width="300"
      >
        {!clicked ? "Send my application" : <CircularProgress color="white" />}
      </Button>
    </>
  );
};

const ChequeModal = props => {
  const [clicked, setClicked] = useState(false);

  return (
    <>
      <Typography variant="h6" id="modal-title">
        Cheque Payment instructions
      </Typography>
      <Typography variant="subtitle1" id="simple-modal-description">
        Please follow the steps outlined below to submit payment
      </Typography>
      <br />

      <Typography variant="subtitle1" id="simple-modal-description">
        1. Please make cheque payable to BrokerLink.
      </Typography>
      <Typography variant="subtitle1" id="simple-modal-description">
        2. Send cheque to the following address:
      </Typography>
      <Typography variant="body1" style={{ fontWeight: "bold" }}>
        818 Victoria St, North
        <br />
        Kitchener, Ontario N2B 3C1
      </Typography>

      <Typography variant="subtitle1" id="simple-modal-description">
        3. Please note your application will not be processed until the cheque
        is received in the office.
      </Typography>

      <Button
        onClick={() => {
          props.submitApplication();
          setClicked(true)
        }}
        disabled={clicked}
        width="300"
      >
        {!clicked ? "Send my application" : <CircularProgress color="white" />}
      </Button>
    </>
  );
};

const CreditDebitModal = props => {

  return (
    <>
      <Typography variant="h6" id="modal-title">
        Credit/Debit payment instructions
      </Typography>
      <Typography variant="subtitle1" id="simple-modal-description">
        Please click below to begin the checkout process
      </Typography>
      <br />
      <br />

      <StripeCheckout
        label="Pay now" //Component button text
        name="ineedapolicy.com" //Modal Header
        description="Apply for your insurance policy today."
        panelLabel="Pay now" //Submit button in modal
        amount={parseInt(props.totalstripe * 100)} //Amount in cents $9.99
        token={t => props.submitApplication(t)}
        stripeKey={process.env.REACT_APP_STRIPE_KEY}
        className="hhh"
        currency="CAD"
        disabled={props.totalstripe <= 0}
        email={props.email}
        // image="https://www.rmtinsurance.com/wp-content/themes/rmt-insurance/images/menu-header.png" //Pop-in header image
        billingAddress={false}
      >
        <Button id="pay-now" onClick={props.closeModal} disabled={props.totalstripe <= 0}>
          Pay Now
        </Button>
      </StripeCheckout>
    </>
  );
};

const Generic = props => {
  const [clicked, setClicked] = useState(false);

  return (
    <>
      <Typography variant="h6" id="modal-title">
        Info
      </Typography>
      <Typography variant="subtitle1" id="simple-modal-description">
        {props.message}
      </Typography>
      <br />
      <br />

      <Button onClick={() => { props.closeModal(); setClicked(true) }} disabled={clicked}>Close</Button>
    </>
  );
};

const GenericYesOrNo = props =>{
  const [clicked, setClicked] = useState(false);

  return (
    <>
      <Typography variant="h6" id="modal-title">
        Info
      </Typography>
      <Typography variant="subtitle1" id="simple-modal-description">
        {props.message}
        <br />
        <br />
        {"Do you wish to continue?"}
      </Typography>
      <Typography variant="caption" id="simple-modal-footer">
        {"Note: Selecting No will reset your progress and all changes will be lost."}
      </Typography>
      <br />
      <br />

          <Button style={{marginRight: 10}} onClick={() => { setClicked(true); props.setAttestation();}} disabled={clicked}>Yes</Button>
          <Button onClick={() => { props.resetForm(); setClicked(true); window.location.reload(); }} disabled={clicked}>No</Button>
      
    </>
  );
}


const Broker = props => {
  const [clicked, setClicked] = useState(false);

  return (
    <>
      <Typography variant="h6" id="modal-title">
        Broker Referral
      </Typography>
      <Typography variant="subtitle1" id="simple-modal-description">
      Thank you for choosing BrokerLink,  One of our Complementary Health Brokers will review and contact you within 2 business days to finalize your insurance needs.
      </Typography>
      <br />
      <Typography variant="subtitle1" id="simple-modal-description-2">
      Please note our Quebec clients are now serviced through Assurapro. One of our Assurapro Brokers will review and contact you within 2 business days to finalize your insurance needs.
      
      If you have any questions please contact Assurapro at 1-833-783-8001 or via email at service@assurapro.ca.
      </Typography>
      <br />
      <br />

      <Button onClick={() => { setClicked(true); props.submitApplication(); }} disabled={clicked} width="300">  {!clicked ? "Send my application" : <CircularProgress color="white" />}
      </Button>
    </>
  );
};

const ErrorModalContent = () => {
  return (
    <>
      <Typography variant="h6" id="modal-title">
        Message
      </Typography>
      <Typography variant="subtitle1" id="simple-modal-description">
        Please validate all remaining fields
      </Typography>
    </>
  );
};

class SimpleModal extends React.Component {
  render() {
    const { classes, modal, closeModal, type } = this.props;

    let Content;

    switch (type) {
      case "Credit/Debit":
        Content = CreditDebitModal;
        break;
      case "E-Transfer":
        Content = EtransferModalContent;
        break;
      case "Cheque":
        Content = ChequeModal;
        break;
      case "Error":
        Content = ErrorModalContent;
        break;
      case "Generic":
        Content = Generic;
        break;
      case "GenericYesOrNo":
        Content = GenericYesOrNo;
        break;
      case "RTB":
        Content = Broker;
        break;
      default:
        Content = ErrorModalContent;

        break;
    }
    // if (type === "Credit/Debit" && validForm) {
    //   closeModal();
    //   return null;
    // }
    return (
      <div>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={modal}
          onClose={() => closeModal()}
        >
          <div style={getModalStyle()} className={classes.paper}>
            <Content {...this.props} />
          </div>
        </Modal>
      </div>
    );
  }
}

SimpleModal.propTypes = {
  classes: PropTypes.object.isRequired
};

// We need an intermediary variable for handling the recursive nesting.
const SimpleModalWrapped = withStyles(styles)(SimpleModal);

export default SimpleModalWrapped;
