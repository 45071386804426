import values from "./values";
import validation from "./validation";

const rmt = {
  selection: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Choose a Registered Massage Therapist policy:"
  },
  school: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Please indicate which RMT School you graduated from"
  },
  schoolRN: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question:
      "Please list your College Registration Number* (If you do\
      not have a CMTO or CMTBC registration number yet, please\
      enter your RMT student number.)"
  },
  goodStandingyesNo: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question:
      "Are you an active member, in good standing with the CMTO or CMTBC?",
    info:
      "Please note you must notify us if your status changes with the College within 30 days of status change"
  },
  practiceOsteopathyyesNo:{
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question:
      "Do you practice Osteopathy?",
    info:
      "If yes an additional premium of $300 needs to be added"
  },
  additionalModalities:{
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question:
      "Do you practice any of these modalities:  Cupping(Non Heat), Hot Stone, Aromatherapy, Reiki or Reflexology?",
    info:
      "Please note there is no additional charge to add any of these modalities to your policy."
  },
  goodStandingapplied: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question:
      "Have you applied, or are you applying, to be a member of the CMTO or CMTBC?",
    info:
      "Please note you must notify us if your status changes with the College within 30 days of status change"
  },
  extraModalities: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question:
      "Do you practice any additional Modalities?",
    info:
      "Please note there is an additional charge of $100.00 annual\
    premium to add any of these modalities to your policy. This added charge is only applicable once."
  },
  extraModalitiesList: {
    defaultvalue: values.EMPTY_ARRAY,
    validation: validation.NOT_EMPTY_ARRAY,
    question: "Specify all additional modalities"
  },
  applying: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question:
      "Is this your first time applying for Registration with the CMTO or the CMTBC?"
  },
  assoc: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question:
      "Have you received your invitation to the join the college as a new member?"
  },
  laser: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Do you offer any type of Laser therapy (Use a Laser machine)?"
  },
  laserDescribe: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Please describe"
  },
  coldOrLowLevelLasers:{
      defaultvalue: values.EMPTY_STRING,
      validation: validation.NOT_EMPTY_STRING,
      question:"Is this a Cold lasers or low-level lasers machine?"
  },
  ownLaserMachine:{
      defaultvalue: values.EMPTY_STRING,
      validation: validation.NOT_EMPTY_STRING,
      question:"Do you own the Laser machine?"
  },
  laserMachineDetails:{
      defaultvalue: values.EMPTY_STRING,
      validation: validation.NOT_EMPTY_STRING,
      question:"Please provide us with the Type of laser machine, make, model, S/N"
  },
  laserMachineAge:{
      defaultvalue: values.EMPTY_STRING,
      validation: validation.NOT_EMPTY_STRING,
      question:"Please provide us with the age of machine, where was it manufactured & value"
  },
  laserMachineMobile:{
      defaultvalue: values.EMPTY_STRING,
      validation: validation.NOT_EMPTY_STRING,
      question:"Please advise if this laser equipment will be used in the clinic only or in different locations / mobile"
  },
  laserMachineWaivers:{
      defaultvalue: values.EMPTY_STRING,
      validation: validation.NOT_EMPTY_STRING,
      question:"Are waivers being signed by each participant?"
  },
  laserMachineMaintenance:{
      defaultvalue: values.EMPTY_STRING,
      validation: validation.NOT_EMPTY_STRING,
      question:"What is the maintenance for this equipment?"
  },
  laserMachineLeasedOwned:{
      defaultvalue: values.EMPTY_STRING,
      validation: validation.NOT_EMPTY_STRING,
      question:"Is the equipment leased or owned by you?"
  },
  laserMachineUse:{
      defaultvalue: values.EMPTY_STRING,
      validation: validation.NOT_EMPTY_STRING,
      question:"What is the machine used for?"
  },
  laserMachineUsedBy:{
      defaultvalue: values.EMPTY_STRING,
      validation: validation.NOT_EMPTY_STRING,
      question:"Will there be other practitioners operating the equipment?"
  },
  laserMachineTraining:{
      defaultvalue: values.EMPTY_STRING,
      validation: validation.NOT_EMPTY_STRING,
      question:"Are you properly trained for operations of this laser machine?"
  },
  skAttestation:{
    defaultValue : values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question:"Do you wish to continue?",
    visble: true
  }
};

export default rmt;
